import { FeatureCollection } from "geojson";

/**
 *
 * @param fc FeatureCollection to parse property field names from
 * @returns {string[]} unique array of property names that exist on the features
 */
export function parseFeatureCollectionPropKeys(
  fc: FeatureCollection
): string[] {
  const props: string[] = [];
  fc.features.forEach((f) => {
    const keys = Object.keys(f.properties);
    props.push(...keys);
  });
  return Array.from(new Set(props));
}

// export function assignProps(
//   arr: Record<string, unknown>[],
//   props: Record<string, string>,
//   mapFeatureProps?: Record<string, string>
// ): Record<string, unknown>[] {
//   const formatted = JSON.parse(JSON.stringify(arr)) as Record<
//     string,
//     unknown
//   >[];
//   formatted.forEach((f, idx) => {
//     const existing = arr[idx];
//     mapFeatureProps &&
//       Object.keys(mapFeatureProps)?.forEach((p) => {
//         f[p] = existing[mapFeatureProps[p]] ?? null;
//       });
//     // NOTE: values passed via props will override any mapFeatureProps values
//     Object.assign(f, props);
//   });
//   return formatted;
// }

/**
 *
 * @param fc FeatureCollection to apply properties to
 * @param props properties to set on each feature's properties
 * @param mapFeatureProps properties to set via mapping by existing property value
 * @returns {FeatureCollection}
 */
export function setFeatureCollectionProps(
  fc: FeatureCollection,
  props: Record<string, string | boolean>,
  mapFeatureProps?: Record<string, string>
): FeatureCollection {
  try {
    const formatted = JSON.parse(JSON.stringify(fc)) as FeatureCollection;

    formatted.features.forEach((f, idx) => {
      // NOTE: holding onto ID
      const currId = f.properties.id;
      f.properties = {
        ...(currId ? { id: currId } : {}),
      };
      const existing = fc.features[idx];
      mapFeatureProps &&
        Object.keys(mapFeatureProps)?.forEach((p) => {
          // NOTE: special handling for isFemale prop matching
          if (p === "isFemale") {
            const val = existing.properties[mapFeatureProps[p]];
            if (["m", "f", "male", "female"].includes(val?.toLowerCase())) {
              f.properties.isFemale = val.toLowerCase().includes("f")
                ? true
                : false;
            }
          } else {
            f.properties[p] = existing.properties[mapFeatureProps[p]] ?? null;
          }
        });
      // NOTE: values passed via props will override any mapFeatureProps values
      Object.assign(f.properties, props);
    });
    return formatted as FeatureCollection;
  } catch (e) {
    console.error(e);
  }
}
