import { useTranslation } from "react-i18next";
import Typography from "@mui/material/Typography";
import { useOrgState } from "providers/OrgProvider";
import {
  useInventoryDispatch,
  useInventoryState,
} from "providers/InventoryProvider";
import {
  useConservationDispatch,
  useConservationState,
} from "providers/ConservationProvider";
import { GeoDataTable } from "components";
import useAreaColumn from "components/Table/useAreaColumn";
import { fitBounds } from "components/Map";
import InventoryCollapseAddButtons from "components/InventoryCollapseAddButtons";
import { FeatureCollection } from "geojson";
import { EditIconButton } from "components/EditIconButton";
import { useConservations } from "api/conservation/useConservations";
import { ConservationItem } from "types/IConservationItem";

export default function ConservationListView({
  expanded,
  toggleExpanded,
}: {
  expanded: boolean;
  toggleExpanded: () => void;
}) {
  const { t } = useTranslation();
  const areaSumHeader = useAreaColumn({ aggregate: true });

  const final = [
    {
      Header: " ",
      isSortable: false,
      accessor: function Cell(row: ConservationItem) {
        return (
          <EditIconButton
            href={`${rootUrl}/inventory/conservation/${row.id}/edit`}
          />
        );
      },
    },
    {
      accessor: "field",
      Header: t("inventory.conservation.fieldAssociations"),
    },
    {
      Header: t("common.startDate"),
      id: "beginOnUtc",
      accessor: (row: { beginOnUtc: string }) => {
        return new Date(row.beginOnUtc).toLocaleDateString();
      },
    },
    {
      Header: "ID",
      accessor: "cid",
    },
    {
      Header: t("common.type"),
      accessor: "conservationType",
    },
    {
      Header: t("common.status"),
      accessor: "conservationStatus",
    },
    {
      Header: t("inventory.conservation.timeEnrolled"),
      accessor: "timeEnrolled",
    },
    {
      Header: "CC",
      accessor: (row: { isCarbonCredit: boolean }) => {
        return row?.isCarbonCredit ? t("common.yes") : t("common.no");
      },
    },
    areaSumHeader,
    { accessor: "farm" },
  ];
  const { org, rootUrl, season } = useOrgState();
  const {
    geodataState,
  }: {
    geodataState: { data: FeatureCollection };
  } = useConservationState();
  const { inventoryMap, ftrsClicked } = useInventoryState();
  const { highlightLayerFeatures } = useInventoryDispatch();
  const { fetchConservationByFieldId } = useConservationDispatch();
  const conservationsQ = useConservations({ org, seasonId: season?.id });

  return (
    <div style={{ overflow: "hidden" }}>
      <div style={{ display: "flex" }}>
        <Typography component={"h3"} variant="h5">
          {t("common.carbon")}
        </Typography>
        <InventoryCollapseAddButtons
          toggleExpanded={toggleExpanded}
          expanded={expanded}
          addUrl={`${rootUrl}/inventory/conservation/add`}
        />
      </div>
      <span style={{ overflow: "auto" }}>
        <GeoDataTable
          isLoading={conservationsQ.isFetching}
          preventGroupedRowColspanExpand
          hiddenColumns={["farm"]}
          columns={final}
          data={conservationsQ.data}
          highlightByIds={highlightLayerFeatures}
          ftrsClicked={ftrsClicked}
          map={inventoryMap}
          featureCollection={geodataState?.data}
          onRowExpand={(row: {
            leafRows: { original: ConservationItem }[];
          }) => {
            const { fieldId } = row.leafRows[0].original;
            // check how many list items for this field
            const listItems = conservationsQ.data?.filter(
              (op) => op.fieldId === fieldId
            );
            // do we already have geodata?
            const existingFtrs = geodataState?.data?.features?.filter((f) => {
              return f.properties.fieldId === fieldId;
            });
            // if no geodata, or if list items length doesn't match geodata length
            // fetch/refetch geodata by fieldId
            if (
              !existingFtrs ||
              !existingFtrs.length ||
              (listItems && listItems.length !== existingFtrs.length)
            ) {
              // TODO:
              fetchConservationByFieldId(fieldId);
            }

            if (existingFtrs && existingFtrs.length && inventoryMap) {
              fitBounds({
                map: inventoryMap,
                geojson: {
                  type: "FeatureCollection",
                  features: existingFtrs,
                },
                options: { animate: false },
              });
            }
          }}
          groupBy={["field"]}
        />
      </span>
    </div>
  );
}
