import { useEffect } from "react";
import {
  Switch,
  Route,
  Redirect,
  useParams,
  useLocation,
  useHistory,
} from "react-router-dom";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import Toolbar from "@mui/material/Toolbar";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import LinearProgress from "@mui/material/LinearProgress";
import { useTranslation } from "react-i18next";
import { useOrgState, useOrgDispatch } from "providers/OrgProvider";
import { useSettingsState } from "views/SettingsView/useSettingsStore";
import agassessorDarkLogo from "src/images/agassessor-dark.png";
import agassessorLightLogo from "src/images/agassessor-light.png";
import DashboardView from "./DashboardView/DashboardView";
import InsightsView from "./InsightsView";
import InventoryView from "./InventoryView";
import ImageryView from "./ImageryView";
import SettingsView from "./SettingsView";
import { useAppState } from "providers/AppProvider";
import { useIsMutating } from "@tanstack/react-query";
import { IUrlParams } from "types";
import AppLayout from "lib/AppLayout";
import SeasonSelector from "./NavbarView/SeasonSelector";
import OrganizationSelector from "./NavbarView/OrganizationSelector";
import AuthNavItem from "components/AuthNavItem";
import SidebarItems from "./SidebarItems";
import ErrorBoundary from "components/ErrorBoundary";
import { VerityModule } from "components/Verity/VerityModule";

export default function OrganizationView() {
  const { t } = useTranslation();
  const isMutating = useIsMutating();
  const theme = useTheme();
  const { org, route, season: seasonName } = useParams<IUrlParams>();
  const { setSidebarOpen, tenant } = useAppState();
  const location = useLocation();
  const history = useHistory();
  const { language } = useSettingsState();
  const {
    fetchDefaultCollectEvents,
    getOrgDarkLogo,
    getOrgLightLogo,
    getEntDarkLogo,
    getEntLightLogo,
    getCurrentSensorTypes,
    dispatch,
  } = useOrgDispatch();

  const {
    rootUrl,
    seasons,
    season,
    seasonsState,
    isDataLoading,
    lightEntLogoState,
    darkEntLogoState,
  } = useOrgState();

  const logo =
    theme.palette.mode === "light"
      ? lightEntLogoState?.data
      : darkEntLogoState?.data;
  const logoError = lightEntLogoState.isError || darkEntLogoState.isError;

  // default data fetch
  // also re-fetches after a language switch
  useEffect(() => {
    if (season) {
      fetchDefaultCollectEvents(org);
      getCurrentSensorTypes();
    }
  }, [fetchDefaultCollectEvents, getCurrentSensorTypes, language, org, season]);

  useEffect(() => {
    if (seasons?.length && !season) {
      const curr = seasons?.find((s) => s.name === seasonName);

      dispatch({
        type: "SET_SEASON",
        payload: curr || seasons[0],
      });
      // couldn't match URL to a season, update URL to new season
      if (!curr) {
        const parts = location?.pathname.split("/");
        parts[2] = seasons[0].name;
        history.replace(parts.join("/") + location.search);
      }
    }
  }, [seasonName, seasons, season, dispatch, location, history]);

  useEffect(() => {
    if (tenant) {
      getEntDarkLogo(tenant);
      getEntLightLogo(tenant);
      getOrgDarkLogo(tenant);
      getOrgLightLogo(tenant);
    }
  }, [
    getEntDarkLogo,
    getEntLightLogo,
    getOrgDarkLogo,
    getOrgLightLogo,
    tenant,
  ]);

  return (
    <AppLayout
      title={
        <Box
          className="hide-print"
          sx={{ display: "flex", alignItems: "center" }}
        >
          <Box sx={{ width: 175 }}>
            <img style={{ width: "100%" }} src={!logoError ? logo?.src : ""} />
          </Box>
          <Box sx={{ ml: 3, width: 175 }}>
            <img
              style={{ width: "100%" }}
              src={
                theme.palette.mode === "light"
                  ? agassessorDarkLogo
                  : agassessorLightLogo
              }
            />
          </Box>
          <Box sx={{ display: "flex", alignItems: "center", ml: "auto" }}>
            <Container maxWidth="xl" sx={{ pr: "0 !important" }}>
              <Toolbar disableGutters>
                <Stack direction={"row"} spacing={2} alignItems={"center"}>
                  <SeasonSelector />
                  <OrganizationSelector />
                  <AuthNavItem />
                </Stack>
              </Toolbar>
            </Container>
          </Box>
        </Box>
      }
      sidebarProps={{
        activeKey: route,
        onToggled: (open) => {
          // BaseMapView needs to resize after open/close, so storing this
          // in the AppProvider
          setSidebarOpen(open);
        },
      }}
      sidebarChildren={<SidebarItems />}
    >
      <ErrorBoundary>
        <Box
          sx={{ p: 3, position: "relative", height: "100%" }}
          className="main"
        >
          {isMutating > 0 || isDataLoading || seasonsState?.isLoading ? (
            <LinearProgress
              style={{ position: "absolute", top: 0, right: 0, left: 0 }}
            />
          ) : null}
          {seasonsState?.isLoading ? (
            <div style={{ textAlign: "center", marginTop: "3rem" }}>
              {t("gettingSeasons")}
            </div>
          ) : null}
          {!season &&
          !seasonsState?.isLoading &&
          seasons &&
          !seasons?.length ? (
            <div style={{ textAlign: "center", marginTop: "3rem" }}>
              <h2>{t("noSeasons")}</h2>
            </div>
          ) : null}
          {season && !seasonsState.isLoading ? (
            <Switch>
              <Route path="/:org/:season/verity" component={VerityModule} />
              <Route path="/:org/:season?" exact component={DashboardView} />
              {/* inventory routes: TODO: simplify to a single route to /inventory/*
               when updating react-router to v6 */}
              <Route path={"/:org/:season/inventory/fields/:editId?"}>
                <InventoryView />
              </Route>
              <Route path={"/:org/:season/inventory/damage/:editId?"}>
                <InventoryView />
              </Route>
              <Route path={"/:org/:season/inventory/products/:type?"}>
                <InventoryView />
              </Route>
              <Route path={"/:org/:season/inventory/conservation/:editId?"}>
                <InventoryView />
              </Route>
              <Route path={`/:org/:season/inventory/scouting`}>
                <InventoryView />
              </Route>
              <Route path={"/:org/:season/inventory/subfields/:type?/:editId?"}>
                <InventoryView />
              </Route>
              <Route
                path={
                  "/:org/:season/inventory/assets/:type/:subtype/:fieldId/items/:editId"
                }
              >
                <InventoryView />
              </Route>
              <Route
                path={
                  "/:org/:season/inventory/assets/:type/:subtype?/:fieldId?"
                }
              >
                <InventoryView />
              </Route>
              <Route path={"/:org/:season/inventory/operations/:type?"}>
                <InventoryView />
              </Route>
              <Route
                path={"/:org/:season/inventory/operations/:type/items/:editId"}
              >
                <InventoryView />
              </Route>
              {/* end inventory routes */}
              <Route path="/:org/:season/imagery">
                <ImageryView />
              </Route>
              <Route path="/:org/:season/insights">
                <InsightsView />
              </Route>
              <Route path="/:org/:season/settings">
                <SettingsView />
              </Route>
              {/* TODO: 404? */}
              <Route path="*">
                <Redirect to={rootUrl} />
              </Route>
            </Switch>
          ) : null}
        </Box>
      </ErrorBoundary>
    </AppLayout>
  );
}
