import { useTheme } from "@mui/material";

export function VerityTrackingLogoText() {
  const theme = useTheme();
  const textFill = theme.palette.mode === "light" ? "#122B7A" : "#fff";
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 368 130" fill="none">
      <path
        d="M162.855 68.8147L180.505 18.0382H170.423L157.502 57.1055L144.588 18.0382H134.5L152.223 68.8147H162.855Z"
        fill={textFill}
      />
      <path
        d="M219.902 60.6354H195.432V47.4151H218.544V39.2358H195.432V26.2175H219.902V18.0382H186.164V68.8147H219.902V60.6354Z"
        fill={textFill}
      />
      <path
        d="M254.051 48.4368C256.865 47.2561 259.104 45.4391 260.762 42.986C262.42 40.5328 263.252 37.5596 263.252 34.0542C263.252 30.6467 262.5 27.7469 261.001 25.361C259.502 22.9751 257.422 21.1582 254.767 19.9102C252.106 18.6622 249.053 18.0382 245.596 18.0382H226.724V68.8147H235.992V50.0702H244.722L255.213 68.8147H265.711L254.051 48.4368ZM252.962 38.1408C252.277 39.3215 251.329 40.2452 250.099 40.8998C248.869 41.5605 247.42 41.8909 245.737 41.8909H235.992V26.2175H245.737C247.42 26.2175 248.876 26.5478 250.099 27.2085C251.329 27.8692 252.277 28.7747 252.962 29.9309C253.641 31.0932 253.984 32.4697 253.984 34.0542C253.984 35.5958 253.641 36.9662 252.962 38.1408Z"
        fill={textFill}
      />
      <path
        d="M281.042 18.0382H271.774V68.8147H281.042V18.0382Z"
        fill={textFill}
      />
      <path
        d="M286.634 26.2175H299.787V68.8147H309.128V26.2175H322.073V18.0382H286.634V26.2175Z"
        fill={textFill}
      />
      <path
        d="M356.497 18.0382L345.351 36.9478L334.137 18.0382H323.578L340.683 46.3201V68.8147H350.019V46.2528L367.063 18.0382H356.497Z"
        fill={textFill}
      />
      <path
        d="M152.223 87.8712H159.521V111.534H163.1V87.8712H170.288V84.6166H152.223V87.8712Z"
        fill={textFill}
      />
      <path
        d="M193.125 97.7695C194.086 96.442 194.569 94.8269 194.569 92.9305C194.569 91.242 194.19 89.7737 193.431 88.5196C192.673 87.2655 191.621 86.305 190.269 85.6321C188.917 84.9591 187.35 84.6227 185.57 84.6227H176.069V111.54H179.612V101.244H185.552L191.357 111.54H195.475L189.222 100.626C190.862 100.045 192.159 99.097 193.125 97.7695ZM185.68 97.9897H179.612V87.8712H185.68C186.739 87.8712 187.669 88.073 188.464 88.4829C189.259 88.8928 189.877 89.4801 190.324 90.2387C190.77 90.9973 190.99 91.8966 190.99 92.9305C190.99 93.9399 190.764 94.833 190.324 95.6039C189.877 96.3747 189.259 96.9681 188.464 97.378C187.669 97.7817 186.739 97.9897 185.68 97.9897Z"
        fill={textFill}
      />
      <path
        d="M208.915 84.6166L199.268 111.534H203.061L205.263 105.215H216.752L218.954 111.534H222.747L213.1 84.6166H208.915ZM206.389 101.96L211.013 88.642L215.638 101.96H206.389Z"
        fill={textFill}
      />
      <path
        d="M234.842 88.8133C236.298 87.9201 237.968 87.4735 239.846 87.4735C241.7 87.4735 243.29 87.9384 244.618 88.8622C245.939 89.7921 246.918 90.9973 247.542 92.49L250.797 91.0095C250.362 89.8288 249.634 88.7215 248.613 87.6876C247.591 86.6537 246.337 85.8095 244.856 85.1549C243.376 84.5064 241.706 84.1822 239.852 84.1822C237.95 84.1822 236.194 84.5248 234.579 85.21C232.964 85.8952 231.569 86.8678 230.388 88.122C229.207 89.3761 228.29 90.8443 227.641 92.5328C226.993 94.2152 226.669 96.0749 226.669 98.0998C226.669 100.1 226.993 101.942 227.641 103.624C228.29 105.313 229.214 106.781 230.406 108.035C231.599 109.289 232.994 110.256 234.597 110.947C236.2 111.632 237.95 111.975 239.852 111.975C241.706 111.975 243.376 111.65 244.856 111.002C246.337 110.347 247.591 109.509 248.613 108.469C249.634 107.435 250.362 106.328 250.797 105.147L247.542 103.667C246.912 105.16 245.939 106.371 244.618 107.295C243.29 108.225 241.7 108.683 239.846 108.683C237.968 108.683 236.298 108.237 234.842 107.344C233.386 106.457 232.248 105.215 231.428 103.624C230.608 102.034 230.198 100.192 230.198 98.0998C230.198 95.977 230.608 94.1234 231.428 92.5328C232.248 90.9422 233.386 89.7003 234.842 88.8133Z"
        fill={textFill}
      />
      <path
        d="M277.971 84.6166H273.524L261.527 98.3997V84.6166H257.985V111.534H261.527V103.587L265.754 98.7483L274.282 111.534H278.509L268.213 95.8731L277.971 84.6166Z"
        fill={textFill}
      />
      <path
        d="M287.839 84.6166H284.297V111.534H287.839V84.6166Z"
        fill={textFill}
      />
      <path
        d="M313.313 104.493L299.181 84.6166H296.22V111.534H299.762V91.3888L313.924 111.534H316.885V84.6166H313.313V104.493Z"
        fill={textFill}
      />
      <path
        d="M337.667 100.333H346.446V100.48C346.446 102.095 346.061 103.514 345.29 104.744C344.519 105.973 343.473 106.934 342.145 107.637C340.824 108.335 339.337 108.683 337.704 108.683C335.826 108.683 334.156 108.237 332.7 107.344C331.244 106.457 330.1 105.215 329.268 103.624C328.436 102.034 328.02 100.192 328.02 98.0998C328.02 95.977 328.43 94.1234 329.249 92.5328C330.069 90.9422 331.207 89.7003 332.663 88.8133C334.119 87.9201 335.789 87.4735 337.667 87.4735C339.57 87.4735 341.185 87.9323 342.506 88.8438C343.834 89.7615 344.806 90.9973 345.43 92.5634L348.612 91.0095C348.177 89.8288 347.449 88.7215 346.428 87.6876C345.406 86.6537 344.152 85.8095 342.671 85.1549C341.191 84.5064 339.521 84.1822 337.667 84.1822C335.765 84.1822 334.009 84.5248 332.394 85.21C330.779 85.8952 329.384 86.8678 328.203 88.122C327.022 89.3761 326.105 90.8443 325.456 92.5328C324.808 94.2152 324.484 96.0749 324.484 98.0998C324.484 100.1 324.814 101.942 325.475 103.624C326.135 105.313 327.059 106.781 328.24 108.035C329.421 109.289 330.815 110.256 332.43 110.947C334.046 111.632 335.801 111.975 337.704 111.975C339.368 111.975 340.946 111.687 342.439 111.106C343.932 110.525 345.247 109.705 346.379 108.628C347.51 107.558 348.404 106.261 349.052 104.744C349.701 103.226 350.025 101.526 350.025 99.6476V97.2617H337.667V100.333Z"
        fill={textFill}
      />
      <path
        d="M17.5182 71.1822L17.2246 62.7276L16.466 40.9549V40.857C16.3742 38.1897 17.9832 35.657 20.626 34.6965C23.9051 33.5036 27.5389 35.1921 28.738 38.4773L28.9521 39.2052C28.9644 39.2725 28.9827 39.3398 28.9949 39.4132L29.0439 39.6947C29.0806 39.9394 29.0989 40.178 29.1112 40.4227L29.1234 40.6551V40.6796L29.2764 45.017L29.882 62.2933L30.1756 70.7417C37.2477 72.5281 43.3347 77.673 46.0204 85.0387C46.4608 86.2561 46.7973 87.4919 47.0236 88.7215L27.6001 83.1483C27.594 83.1483 27.5879 83.1483 27.5818 83.1422L27.4716 83.1055C27.4472 83.1055 27.4288 83.0994 27.4166 83.0933C25.3488 82.4937 23.1893 82.5671 21.1582 83.3074C17.1818 84.7573 14.5022 88.5686 14.4961 92.7959C14.4961 92.8203 14.4961 92.8509 14.4961 92.8754L14.5022 93.065C14.5022 93.0712 14.5083 93.1201 14.5083 93.1323C14.5451 94.2213 14.7469 95.2674 15.114 96.2768C15.273 96.7112 15.4443 97.1027 15.6462 97.482C15.7135 97.6105 15.7992 97.7634 15.8848 97.9102C15.9643 98.057 16.0806 98.2344 16.1968 98.4057C16.2029 98.418 16.2335 98.4669 16.2458 98.4791C16.3559 98.6504 16.4721 98.8034 16.5945 98.9686C16.6985 99.1032 16.8025 99.2255 16.9065 99.3479C17.1389 99.6293 17.4265 99.9352 17.769 100.247C17.7874 100.266 17.7996 100.272 17.818 100.29C18.2585 100.688 18.7234 101.042 19.2006 101.342C19.4025 101.471 19.5676 101.569 19.7451 101.66C20.3018 101.972 20.938 102.242 21.6171 102.462C21.6782 102.486 21.7455 102.505 21.8128 102.523L22.0269 102.584C22.0759 102.596 22.1187 102.609 22.1676 102.627L41.4688 108.163C39.0707 110.8 36.018 112.905 32.4331 114.208C20.6138 118.508 7.54033 112.415 3.23963 100.596C-1.06108 88.7766 5.0321 75.7031 16.8514 71.4024C17.0472 71.3413 17.2858 71.2617 17.5182 71.1822Z"
        fill="#FFB400"
      />
      <path
        d="M111.797 85.3323C106.842 87.137 101.673 87.1126 97.017 85.6076L92.2881 92.6185L80.12 110.659L80.0528 110.763C79.3309 111.834 78.2786 112.697 76.9695 113.174C76.8655 113.21 76.7615 113.247 76.6575 113.278C76.5902 113.302 76.5229 113.321 76.4495 113.339C76.0457 113.449 75.6419 113.516 75.2443 113.541C75.1709 113.547 75.0975 113.553 75.024 113.553C75.0118 113.559 75.0118 113.559 74.9996 113.559C74.9935 113.559 74.9873 113.559 74.9812 113.559C74.8895 113.559 74.7977 113.559 74.712 113.553C74.6142 113.553 74.5163 113.553 74.4245 113.547L74.241 113.535C74.137 113.529 74.033 113.516 73.929 113.498C73.8372 113.492 73.7332 113.474 73.6353 113.449L73.4946 113.425C73.3662 113.4 73.2377 113.37 73.1092 113.333C73.0909 113.327 73.0786 113.321 73.0603 113.321L72.8645 113.266C71.072 112.684 69.5549 111.314 68.8574 109.405C68.1356 107.429 68.4659 105.319 69.561 103.698L69.6833 103.52L72.1304 99.8862L81.7902 85.5648L86.5191 78.554C84.8674 76.578 83.5276 74.29 82.5977 71.7328C80.4137 65.7375 80.9092 59.4241 83.4664 54.0956L95.8057 69.8914C98.4485 73.5375 103.22 75.0057 107.46 73.458C109.35 72.7667 110.99 71.537 112.177 69.9097C112.207 69.873 112.232 69.8363 112.256 69.7996L112.385 69.6161C114.195 66.9243 114.611 63.5168 113.51 60.4885C113.143 59.4791 112.623 58.5431 111.969 57.7111L99.4151 41.6401C110.305 39.3888 121.481 45.4024 125.403 56.1634C129.71 67.9582 123.617 81.0316 111.797 85.3323Z"
        fill="#FFB400"
      />
      <path
        d="M90.3121 36.1281L95.5182 42.7902L108.959 59.9992L108.989 60.0359C109.393 60.5498 109.711 61.1248 109.95 61.7672C110.672 63.7432 110.341 65.8476 109.252 67.4688L109.124 67.6523C108.402 68.6373 107.399 69.4265 106.169 69.873C103.434 70.8702 100.455 69.8608 98.8523 67.5973L96.1973 64.202L85.5587 50.578L80.3464 43.9159C80.1139 44.0076 79.8815 44.0933 79.649 44.1789C71.2495 47.2377 62.2137 45.0415 56.1511 39.2664L75.3238 32.2862C80.5728 30.3775 83.2829 24.5596 81.3742 19.3107C81.0255 18.3502 80.5361 17.457 79.9304 16.6556C79.9182 16.6311 79.8998 16.6067 79.8815 16.5883L79.8203 16.5149C77.1285 13.0768 72.5342 11.7615 68.4048 13.2664L49.2321 20.2467C50.1681 11.9267 55.6801 4.43254 64.0735 1.37983C75.8928 -2.92088 88.9601 3.16619 93.2669 14.9916C95.9403 22.3573 94.5822 30.2184 90.3121 36.1281Z"
        fill="#FFB400"
      />
      <path
        d="M76.8411 18.8824L76.9022 18.9558C77.2754 19.4453 77.5813 20.002 77.8015 20.6137C78.9945 23.8928 77.306 27.5267 74.0208 28.7257L45.5187 39.1013C46.1182 47.8984 41.5239 56.4265 33.6137 60.6843L32.9163 40.6185C32.9163 40.5756 32.9163 40.5389 32.9102 40.5083L32.898 40.2758C32.8857 39.9149 32.8551 39.5417 32.794 39.1563C32.7879 39.1196 32.7817 39.0829 32.7756 39.0523L32.7267 38.7709C32.7144 38.7281 32.7144 38.6853 32.7022 38.6424L32.6594 38.4344C32.6349 38.3304 32.6104 38.2264 32.586 38.1224L32.3719 37.3944C32.3535 37.321 32.3229 37.2476 32.2984 37.1803C30.3897 31.9314 24.5718 29.2213 19.3229 31.13C15.1874 32.6349 12.514 36.5991 12.6669 40.9916V41.0712L13.3765 61.3817C7.99913 58.9469 3.56383 54.426 1.37983 48.4307C-2.92088 36.6053 3.16619 23.5441 14.9916 19.2373C24.7493 15.689 35.3573 19.2128 41.1813 27.2086L69.6834 16.833C72.3385 15.8726 75.2015 16.7841 76.8411 18.8824Z"
        fill="#FFB400"
      />
      <path
        d="M23.1097 98.9808L23.073 98.9747L22.8589 98.9135V98.9074C22.3939 98.7667 21.9412 98.5832 21.513 98.3446C21.4029 98.2834 21.2989 98.2222 21.1949 98.1549C20.889 97.9592 20.5954 97.7328 20.3201 97.4881C20.1243 97.3046 19.9346 97.115 19.7633 96.907C19.6961 96.8274 19.6288 96.7479 19.5676 96.6684C19.4881 96.5705 19.4208 96.4665 19.3473 96.3686C19.2739 96.2585 19.2066 96.1545 19.1455 96.0443C19.0843 95.9403 19.0292 95.8363 18.9742 95.7323C18.8518 95.4999 18.7417 95.2491 18.6499 94.9921C18.4175 94.3498 18.2951 93.7013 18.2706 93.0528V93.0039L18.2645 92.8142C18.2706 90.2387 19.8673 87.8161 22.4306 86.8801C23.7398 86.4029 25.0979 86.3907 26.3398 86.7455L26.4622 86.7822L26.5111 86.7945L26.5295 86.8006L30.7629 88.0119L47.3784 92.7775L55.5088 95.1145C58.1027 90.9667 62.0547 87.6265 67.0038 85.8218C70.5827 84.5187 74.2777 84.17 77.8137 84.6472L66.4288 101.532C64.5996 104.236 64.1775 107.65 65.2848 110.69C66.3615 113.645 68.6923 115.89 71.69 116.863C71.7328 116.875 71.7695 116.887 71.8123 116.899L71.9714 116.942C72.0203 116.954 72.0754 116.973 72.1243 116.985C72.3201 117.04 72.5648 117.101 72.7912 117.144L72.8829 117.156C73.0481 117.187 73.2072 117.218 73.3662 117.242C73.5681 117.279 73.7639 117.297 73.9719 117.309L74.1187 117.322C74.2655 117.334 74.4551 117.34 74.657 117.34C74.7733 117.34 74.8895 117.34 75.0118 117.34C75.0914 117.34 75.177 117.34 75.2627 117.328C75.3483 117.322 75.434 117.322 75.5135 117.309C76.1253 117.273 76.7493 117.169 77.361 117.01C77.5262 116.973 77.6853 116.924 77.8504 116.869C77.9911 116.826 78.1319 116.777 78.2664 116.728C80.2914 115.988 81.9982 114.66 83.1973 112.868L83.2952 112.727L94.6067 95.9587C95.2246 97.0538 95.7629 98.21 96.2095 99.4274C100.51 111.247 94.4232 124.314 82.5977 128.621C70.7784 132.922 57.705 126.828 53.4043 115.009C52.4744 112.458 52.0278 109.84 52.0217 107.264L43.8975 104.933L23.1097 98.9808Z"
        fill="#FFB400"
      />
    </svg>
  );
}
