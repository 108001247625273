import { useTheme } from "@mui/material/styles";
import { SidebarListItem, useSidebarState } from "lib/Sidebar";
import {
  DashboardIcon,
  ImageryIcon,
  InsightsIcon,
  InventoryIcon,
} from "lib/Icons";
import caiLogoDark from "lib/cai-logo-dark.png";
import caiLogoLight from "lib/cai-logo-light.png";
import SettingsIcon from "@mui/icons-material/Settings";
import Box from "@mui/material/Box";
import SidebarListTreeItem from "lib/Sidebar/SidebarListTreeItem";
import CAIIcon from "lib/Icons/CAIIcon";
import { useOrgState } from "providers/OrgProvider";
import { useTranslation } from "react-i18next";
import Typography from "@mui/material/Typography";
import { VerityLogo } from "lib/Icons/VerityLogo";
import { VerityTrackingLogoText } from "lib/VerityTrackingLogoText";
export default function SidebarItems() {
  const { configQuery, rootUrl } = useOrgState();
  const { t } = useTranslation();
  const { open } = useSidebarState();
  const theme = useTheme();
  return (
    <>
      <SidebarListItem
        selected={location.pathname === rootUrl}
        href={rootUrl}
        icon={<DashboardIcon />}
        text={t("dashboard.title")}
      />
      {/* inventory tree item */}
      <SidebarListTreeItem
        eventKey="inventory"
        open={location.pathname.includes("inventory")}
        icon={<InventoryIcon />}
        text={t("inventory.title")}
      >
        <SidebarListItem
          href={`${rootUrl}/inventory/fields`}
          selected={location.pathname.includes("inventory/fields")}
          text={t("inventory.fields.title")}
        />
        <SidebarListItem
          href={`${rootUrl}/inventory/subfields`}
          selected={location.pathname.includes("inventory/subfields")}
          text={t("inventory.subfields.title")}
        />
        <SidebarListItem
          href={`${rootUrl}/inventory/assets/plant`}
          selected={location.pathname.includes("inventory/assets")}
          text={t("inventory.assets.title")}
        />
        <SidebarListItem
          href={`${rootUrl}/inventory/operations/planted`}
          selected={location.pathname.includes("inventory/operations")}
          text={t("inventory.operations.title")}
        />

        <SidebarListItem
          href={`${rootUrl}/inventory/products/seed`}
          selected={location.pathname.includes("inventory/products")}
          text={t("inventory.products.title")}
        />

        <SidebarListItem
          href={`${rootUrl}/inventory/damage`}
          selected={location.pathname.includes("inventory/damage")}
          text={t("inventory.damage.title")}
        />

        <SidebarListItem
          href={`${rootUrl}/inventory/conservation`}
          selected={location.pathname.includes("inventory/conservation")}
          text={t("common.carbon")}
        />

        <SidebarListItem
          href={`${rootUrl}/inventory/scouting`}
          selected={location.pathname.includes("inventory/scouting")}
          text={t("common.scouting")}
        />
      </SidebarListTreeItem>
      {/* IMAGERY TREE ITEM */}
      <SidebarListTreeItem
        eventKey="imagery"
        open={location.pathname.includes("imagery")}
        icon={<ImageryIcon />}
        text={t("imagery.title")}
        // title={t("imagery.title")}
      >
        <SidebarListItem
          href={`${rootUrl}/imagery/compare`}
          selected={location.pathname.includes("imagery/compare")}
          text={t("imagery.compare.title")}
        />
        <SidebarListItem
          href={`${rootUrl}/imagery/extract`}
          selected={location.pathname.includes("imagery/extract")}
          text={t("imagery.extract.title")}
        />
        <SidebarListItem
          href={`${rootUrl}/imagery/rx`}
          selected={location.pathname.includes("imagery/rx")}
          text={t("imagery.rx.title")}
        />
        <SidebarListItem
          href={`${rootUrl}/imagery/manage`}
          selected={location.pathname.includes("imagery/manage")}
          text={t("imagery.manage.title")}
        />
        <SidebarListItem
          href={`${rootUrl}/imagery/import`}
          selected={location.pathname.includes("imagery/import")}
          text={t("imagery.import.title")}
        />
      </SidebarListTreeItem>
      {/* INSIGHTS TREE ITEM */}
      <SidebarListTreeItem
        eventKey="insights"
        icon={<InsightsIcon />}
        open={location.pathname.includes("insights")}
        text={t("insights.title")}
      >
        <SidebarListItem
          href={`${rootUrl}/insights/yield/productapplied`}
          text={t("insights.yield.title")}
          selected={
            location.pathname.includes("yield") &&
            !location.pathname.includes("estimation")
          }
        />
        <SidebarListItem
          href={`${rootUrl}/insights/reports/fields`}
          text={t("inventory.fields.title")}
          selected={
            location.pathname.includes("reports/fields") ||
            location.pathname.includes("reports/subfields")
          }
        />
        <SidebarListItem
          href={`${rootUrl}/insights/reports/operations/planted`}
          text={t("inventory.operations.title")}
          selected={location.pathname.includes("reports/operations")}
        />
        <SidebarListItem
          href={`${rootUrl}/insights/reports/assets/plant`}
          text={t("inventory.assets.title")}
          selected={location.pathname.includes("reports/assets")}
        />
        <SidebarListItem
          href={`${rootUrl}/insights/yield/estimation`}
          text={t("insights.yieldEstimation")}
          selected={location.pathname.includes("yield/estimation")}
        />
        <SidebarListItem
          href={`${rootUrl}/insights/imagery/subfields`}
          text={t("imagery.title")}
          selected={location.pathname.includes("insights/imagery")}
        />
      </SidebarListTreeItem>
      <div style={{ marginTop: "auto" }}>
        {configQuery.data?.allowedModules.includes("verity") ? (
          <SidebarListItem
            href={`${rootUrl}/verity`}
            icon={!open ? <VerityLogo /> : null}
            text={
              <div style={{ marginLeft: "0.5rem", width: "50%" }}>
                <VerityTrackingLogoText />
              </div>
            }
            selected={location.pathname.startsWith(`${rootUrl}/verity`)}
          />
        ) : null}

        <SidebarListItem
          href={`${rootUrl}/settings`}
          icon={<SettingsIcon />}
          text={t("settings.title")}
          selected={location.pathname.startsWith(`${rootUrl}/settings`)}
        />
        <SidebarListItem
          component="a"
          sx={{ color: "inherit", "a:hover": { color: "currentColor" } }}
          target="_blank"
          href="https://www.cultivateagi.com"
          icon={<CAIIcon />}
          text={
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography variant="subtitle2" textTransform={"uppercase"}>
                {t("settings.poweredBy")}
              </Typography>
              <img
                style={{ marginLeft: "-1rem", width: "170px" }}
                src={
                  theme.palette.mode === "light" ? caiLogoLight : caiLogoDark
                }
              />
              <Typography textTransform={"none"} variant="subtitle2">
                www.cultivateagi.com
              </Typography>
            </Box>
          }
        />
      </div>
    </>
  );
}
