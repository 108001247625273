import { useCallback } from "react";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { IOrgConfig } from "types/IOrgConfig";

const orgKeys = {
  config: (org: string) => ["organization", org, "config"],
};

function validateVerityModule(org: string) {
  // TODO: get this from API
  const verityModuleOrgs = [
    "demo",
    "folds",
    "mmovall",
    "caple",
    "demodeppe",
    "demostfrd",
  ];
  return verityModuleOrgs.includes(org);
}

export function useOrgConfig(org: string) {
  const queryFn = useCallback(async () => {
    const url = `/${org}/config`;
    const res = await axios.get<IOrgConfig>(url);
    if (res.data) {
      res.data.allowedModules = validateVerityModule(org) ? ["verity"] : [];
    }
    return res.data;
  }, [org]);

  return useQuery({
    queryKey: orgKeys.config(org),
    queryFn,
    enabled: Boolean(org),
  });
}
