import { useEffect, useState } from "react";
import { Route, useParams } from "react-router-dom";
import Paper from "@mui/material/Paper";
import { useTranslation } from "react-i18next";
import { useInventoryDispatch } from "providers/InventoryProvider";
import { useConservationState } from "providers/ConservationProvider";
import CardStack from "../../CardStack";
import ConservationListView from "./ConservationListView";
import ConservationSummaryView from "./ConservationSummaryView";
import { CreateOrEditConservation } from "./CreateOrEditConservation";
import ConservationMapThemesView from "./ConservationMapThemesView";
import { IUrlParams } from "types";

export function ConservationView() {
  const { t } = useTranslation();
  const [expanded, setExpanded] = useState<boolean>();
  const { editId } = useParams<IUrlParams>();
  const { geodataState } = useConservationState();
  const {
    setInventoryFtrs,
    setLegendData,
    setColSizes,
    setTitle,
    setLabelProp,
  } = useInventoryDispatch();

  useEffect(() => {
    setTitle(t("common.carbon"));
    setLabelProp("cid");
    setColSizes([7, 5]);
  }, [setColSizes, setTitle, setLabelProp, t]);

  useEffect(() => {
    setLegendData({
      title: "Type",
      colorProp: "conservationTypeColor",
      dataProp: "conservationType",
    });
  }, [setLegendData]);

  useEffect(() => {
    setInventoryFtrs(geodataState?.data);
  }, [geodataState, setInventoryFtrs]);

  return (
    <CardStack
      expanded={Boolean(editId || expanded)}
      topChildren={
        <>
          <Paper>
            <ConservationSummaryView />
          </Paper>
          <Paper>
            <ConservationMapThemesView
              onThemeChange={(theme) => {
                setLegendData({
                  title: theme === "conservationType" ? "Type" : "Status",
                  colorProp:
                    theme === "conservationType"
                      ? "conservationTypeColor"
                      : "conservationStatusColor",
                  dataProp: theme,
                });
              }}
            />
          </Paper>
        </>
      }
    >
      <Paper sx={{ p: 3, height: "100%", display: "flex", maxHeight: "100%" }}>
        <Route path="/:org/:season/inventory/conservation" exact>
          <ConservationListView
            toggleExpanded={() => {
              setExpanded(!expanded);
            }}
            expanded={expanded}
          />
        </Route>
        <Route path={"/:org/:season/inventory/conservation/add"}>
          <CreateOrEditConservation />
        </Route>
        <Route path={"/:org/:season/inventory/conservation/:editId/edit"}>
          <CreateOrEditConservation />
        </Route>
      </Paper>
    </CardStack>
  );
}
