import { useState, useEffect } from "react";
import { Route, useParams } from "react-router-dom";
import Paper from "@mui/material/Paper";
import { useTranslation } from "react-i18next";
import { useInventoryDispatch } from "providers/InventoryProvider";
import { useDamageState } from "providers/DamageProvider";
import CreateOrEditDamage from "./CreateOrEditDamage";
import DamageListView from "./DamageListView";
import MapThemesView from "./MapThemes";
import DamageSummaryView from "./SummaryView";
import CardStack from "../../CardStack";
import { usePrevious } from "components";
import { IUrlParams } from "types";

export function DamageView() {
  const { t } = useTranslation();
  const {
    setInventoryFtrs,
    setLegendData,
    setColSizes,
    setTitle,
    setLabelProp,
  } = useInventoryDispatch();
  const [expanded, setExpanded] = useState<boolean>();
  const { editId } = useParams<IUrlParams>();
  const {
    damageList,
    damageGeodata,
    damageThemeRollup,
    damageThemeData,
    isFetchingData,
  } = useDamageState();
  const prevDamageGeodata = usePrevious(damageGeodata);

  useEffect(() => {
    setTitle(t("inventory.damage.title"));
    setLabelProp("damageType");
    setColSizes([5, 7]);
  }, [setColSizes, setTitle, setLabelProp, t]);

  // set legend data
  useEffect(() => {
    setLegendData({
      title: t("common.type"),
      colorProp: "damageTypeColor",
      dataProp: "damageType",
    });
    return () => {
      setLegendData(null);
    };
  }, [setLegendData, t]);

  useEffect(() => {
    if (damageGeodata !== prevDamageGeodata) {
      setInventoryFtrs(damageGeodata);
    }
  }, [damageGeodata, prevDamageGeodata, setInventoryFtrs]);

  useEffect(() => {
    return () => {
      setInventoryFtrs(null);
    };
  }, [setInventoryFtrs]);

  return (
    <Route path="/:org/:season/inventory/damage">
      <CardStack
        // topHeight={"310px"}
        expanded={Boolean(editId || expanded)}
        topChildren={
          <>
            <Paper>
              <DamageSummaryView />
            </Paper>
            <Paper>
              <MapThemesView
                themeType="damage-type"
                onThemeTypeChange={() => {
                  // TODO: when adding new theme types
                }}
                themeData={damageThemeRollup?.damageType}
              />
            </Paper>
          </>
        }
      >
        <Paper
          sx={{
            p: 3,
            height: "100%",
            display: "flex",
            flexDirection: "column",
            maxHeight: "100%",
          }}
        >
          <Route path="/:org/:season/inventory/damage" exact>
            <DamageListView
              toggleExpanded={() => {
                setExpanded(!expanded);
              }}
              expanded={expanded}
              isLoading={isFetchingData}
              damages={damageList}
              themeProp="damageTypeColor"
              themeData={damageThemeData}
              geodata={damageGeodata}
            />
          </Route>
          <Route path={"/:org/:season/inventory/damage/add"} exact>
            <CreateOrEditDamage />
          </Route>
          <Route path={"/:org/:season/inventory/damage/:editId/edit"} exact>
            <CreateOrEditDamage />
          </Route>
        </Paper>
      </CardStack>
    </Route>
  );
}
